import React, { useState, useEffect, useRef, createRef } from "react";
import { gsap } from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Arrow from "../assets/svg/arrowhistory.inline.svg";
import Pointer from "../assets/svg/historypointer.inline.svg";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import SEO from "../components/seo";
import Nav from "../components/Nav";

const HistoryArrow = styled(Arrow)`
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
	position: absolute;
	bottom: 0;
	@media (min-width: 62em) {
		position: relative;
	}
`;

const HistoryPointer = styled(Pointer)``;

const HistoryPointerText = styled.p`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 1rem;
	line-height: 0.94;
	margin-left: 12px;
`;

const HistoryText = styled.p`
	font-family: "Poppins";
	font-weight: 300;
	font-style: normal;
	font-size: 1rem;
	line-height: 1.4;
	color: #1a1818;
	margin-top: ${(props) => props.margintop}px;
`;

const TimeLineBall = styled.div`
	position: absolute;
	top: ${(props) => props.index * 54}px;
	--size: 18px;
	width: var(--size);
	height: var(--size);
	border-radius: calc(var(--size) / 2);
	background-color: #bfb8af;
`;

export default function HistoriaPage({  page  }) {
	const breakpoints = useBreakpoint();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [prevIndex, setPrevIndex] = useState(null);
	const [slides, setSlides] = useState([]);

	const ballRefs = useRef([]);
	ballRefs.current = [];

	const timeline = createRef();

	const addToRefs = (el) => {
		if (el && !ballRefs.current.includes(el)) {
			ballRefs.current.push(el);
		}
	};

	useEffect(() => {
		setSlides(page.History.historyRepeater);
	}, [page.History.historyRepeater]);

	useEffect(() => {
		let nextY = 0;
		if (currentIndex > 0 && currentIndex < slides.length) {
			nextY = -54 * currentIndex;
		}
		gsap.to(timeline.current, { duration: 0.15, y: nextY });
		gsap.to(ballRefs.current[currentIndex], {
			duration: 0.15,
			scale: 1.85,
			backgroundColor: "#fcafc0",
		});
		
		ballRefs.current.map( (item, index) => {
			if (index < currentIndex) {
				return item.style.backgroundColor = "#fcafc0";
			}
			if(index > currentIndex) {
				return item.style.backgroundColor = "#bfb8af";
			}
		})

	}, [currentIndex, slides, timeline]);

	useEffect(() => {
		gsap.to(ballRefs.current[prevIndex], { duration: 0.15, scale: 1 });
	}, [prevIndex]);

	const doSlides = (index) => {
		setPrevIndex(currentIndex);
		setCurrentIndex(index);
	};

	const gotoNext = () => {
		setPrevIndex(currentIndex);
		if (currentIndex >= slides.length - 1) {
			setCurrentIndex(0);
		} else {
			setCurrentIndex(currentIndex + 1);
		}
	};

	return (
		<>
		<SEO title={page.seo.title} description={page.seo.metaDesc} />
		<Nav />
		<Box pos="relative" minHeight={{ md: 570}}>
			<Box
				w={["80px", "110px"]}
				h="100%"
				pos="absolute"
				top="0px"
				left={["-36px", "-36px", "50%"]}
				ml={[0, 0, "-55px"]}
				mt={[0, 0]}
				display={{ md: "flex"}}
				flexDirection={{ md: "column"}}
				justifyContent={{ md: "space-between"}}
				//background={"linear-gradient(90deg, rgba(0,0,0,0) calc(50% - 2px), rgba(255,198,0,1) calc(50%), rgba(0,0,0,0) calc(50% + 2px))" }
			>
				<Flex
					justifyContent="center"
					pos="relative"
					w={"110px"}
					mt={["80px", "80px", "160px"]}
					ref={timeline}
				>
					{page.History.historyRepeater.map((item, i) => (
						<TimeLineBall
							key={i}
							index={i}
							onClick={() => doSlides(i, item)}
							ref={addToRefs}
						/>
					))}
				</Flex>
				{breakpoints.sm ? null : (
					<HistoryArrow className="arrow" onClick={gotoNext} />
				)}
			</Box>

			{slides.length > 0 && (
				<Flex
					flexDir={["column", "column","row"]}
					justifyContent="center"
					//alignItems="stretch"
					//h={["auto", `calc(100vh - 246px)`]}
					h={["auto", "auto",`100%`]}
					pt={["20px", "20px", "60px"]}
				>
					<Box w={["100%", "100%","50%"]} mr={[0, 0, "50px"]} ml={["50px", "50px",0]}>
						{breakpoints.sm ? (
							<>
								<Heading as="h1" size="2xl" fontWeight={900} textTransform="lowercase">
									{page.title}
								</Heading>

								<Flex
									alignItems="center"
									pos="absolute"
									top="80px"
									left="20px"
									h="45px"
								>
									<HistoryPointer />
									<HistoryPointerText>
										{slides[currentIndex].historyHeading}
									</HistoryPointerText>
								</Flex>
							</>
						) : null}
						<Box w={["180px","180px", "100%"]} maxWidth={"400px"} h="auto" mt={["80px", "80px", "40px"]} ml={{ md: "auto" }}>
							{slides[currentIndex].historyImage ? (
								<GatsbyImage
									image={
										slides[currentIndex].historyImage
											?.localFile.childImageSharp.gatsbyImageData
									}
									style={{
										maxWidth: "400px",
										maxHeight: "400px",
									}}
									imgStyle={{ objectFit: "contain", objectPosition: "right top" }}
									alt=""
								/>
							) : null}
						</Box>
					</Box>

					<Box
						pos="relative"
						w={["100%", "100%", "50%"]}
						ml={[0, 0, "50px"]}
						pl={["50px","50px", 0]}
						pr={["16px","16px", 0]}
						pb={["2rem", "2rem", 0]}
					>
						<Box maxWidth={"400px"}>
						{breakpoints.sm ? null : (
							<>
								<Heading
									as="h1"
									size="2xl"
									fontWeight={900}
									w="200px"
									lineHeight={0.94}
									textTransform="lowercase"
								>
									<br/>{page.title}
								</Heading>

								<Flex
									alignItems="center"
									pos="absolute"
									top="100px"
									left="-50px"
									h="45px"
								>
									<HistoryPointer />
									<HistoryPointerText>
										{slides[currentIndex].historyHeading}
									</HistoryPointerText>
								</Flex>
							</>
						)}
						
						<HistoryText margintop={breakpoints.sm ? 20 : 80}>
							{slides[currentIndex].historyText}
						</HistoryText>
						</Box>
					</Box>
				</Flex>
			)}
		</Box>
		</>
	);
}