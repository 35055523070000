import React from "react";
import { graphql } from 'gatsby';
import History from "../../templates/HistoryTemplate";

export default function HistoryPage({ data: { page } }) {
    
	return <History page={page} locale="en" />
}


export const query = graphql`
	fragment HistoryImage on File {
		childImageSharp {
			fluid(maxWidth: 400) {
				...GatsbyImageSharpFluid
			}
		}
	}
	query {
		page: wpPage(slug: { eq: "history" }) {
			slug
			title
			seo {
				metaDesc
				title
			}
            locale {
                locale
            }
			History {
				historyRepeater {
					historyHeading
					historyText
					historyImage {
						localFile {
							childImageSharp { 
								gatsbyImageData(width: 400)
							}
						}
					}
				}
			}
		}
	}
`;